<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Reklamacje</b> w tym miejscu znajdują się zgłoszone reklamacje.
      </div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          title="Lista zgłoszonych reklamacji"
          v-if="state === 'loaded'"
        >
          <template v-slot:preview>
            <div>
              <b-row>
                <b-col></b-col>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filtuj"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Szukaj..."
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Wyczyść</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                responsive
                striped
                :items="claims.items"
                :fields="claims.fields"
                :filter="filter"
                :tbody-tr-class="rowClass"
              >
                <template #cell(actions)="row">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-1"
                    @click="editClaim(row.item)"
                  >
                    <b-icon icon="pencil" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </template>
        </KTCodePreview>
        <div class="d-flex justify-content-center mb-3" v-else>
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
    </div>

    <template>
      <b-modal
        id="editClaim"
        size="xl"
        scrollable
        ref="claimDetail"
        :title="'Status: ' + claim.status"
      >
        <b-container>
          <div class="row">
            <div class="col-md-6">
              <b-list-group>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      Powód:
                    </div>
                    <div class="col-md-6">
                      <b-badge>{{ reason(claim) }}</b-badge>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      Akronim:
                    </div>
                    <div class="col-md-6">
                      {{ claim.erpUser.acronym }}
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      E-mail:
                    </div>
                    <div class="col-md-6">
                      {{ claim.erpUser.email }}
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div class="col-md-6">
              <b-list-group>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      Status:
                    </div>
                    <div class="col-md-6">
                      <b-badge>{{ status(claim) }}</b-badge>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      Imię nazwisko:
                    </div>
                    <div class="col-md-6">
                      {{ claim.erpUser.person }}
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      Telefon:
                    </div>
                    <div class="col-md-6">
                      {{ claim.erpUser.phone }}
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
          <hr />
          <b-form novalidate :validated="formValidated">
            <b-form-group
              id="fieldset-1"
              label="Treść reklamacji"
              label-for="description"
            >
              <b-row>
                <b-col cols="12">
                  <b-form-textarea
                    :value="claim.description"
                    :disabled="true"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-col>

                <b-col cols="3" />
              </b-row>
            </b-form-group>

            <b-form-group
              id="fieldset-1"
              label="Odpowiedź Administratora"
              label-for="description"
            >
              <b-row>
                <b-col cols="12">
                  <b-form-textarea
                    v-model="claim.response"
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                    Proszę wpisać odpowiedź.
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
        </b-container>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button
            variant="success"
            size="sm"
            class="float-right"
            @click="saveClaim('ZAAKCEPTOWANA')"
          >
            Akceptuj
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="saveClaim('ODRZUCONA')"
          >
            Odrzuć
          </b-button>
          <b-button
            variant="outline-secondary"
            size="sm"
            class="float-right"
            @click="hide()"
          >
            Zamknij
          </b-button>
        </template>
      </b-modal>
    </template>
  </div>
</template>
<style>
.action-column {
  width: 50px;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import KTCodePreview from "@/view/content/CodePreview";

export default {
  name: "ClaimsAdmin",
  data() {
    return {
      state: "loading",
      claims: {
        fields: [
          {
            key: "date",
            label: "Data",
            sortable: true
          },
          {
            key: "erpUser.acronym",
            label: "Akronim",
            sortable: true
          },
          {
            key: "reason",
            label: "Powód Reklamacji",
            sortable: true
          },
          {
            key: "status",
            label: "Status",
            sortable: true
          },
          {
            key: "actions",
            label: "Akcje",
            thClass: "action-column"
          }
        ],
        items: []
      },
      claim: {
        erpUser: {}
      },
      filter: null,
      formValidated: false
    };
  },
  components: {
    KTCodePreview
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "ODRZUCONA") return "table-danger";
      if (item.status === "ZAAKCEPTOWANA") return "table-success";
    },
    editClaim(row) {
      this.formValidated = false;
      this.claim = row;
      this.$refs.claimDetail.show("editClaim");
    },
    saveClaim(status) {
      if (this.claim.response && this.claim.response.trim().length > 0) {
        const oldStatus = this.claim.status;
        this.claim.status = status;
        ApiService.post("/claim-save", this.claim)
          .then(() => {
            this.$refs.claimDetail.hide("editClaim");
          })
          .catch(() => {
            this.formValidated = true;
            this.claim.status = oldStatus;
          });
      } else {
        this.formValidated = true;
      }
    },
    reason(claim) {
      switch (claim.reason) {
        case "BŁĘDNE_NALICZENIE":
          return "Błędne naliczenie";
        case "BRAK_WYSYŁKI":
          return "Brak wysyłki";
        case "USZKODZONA_NAGRODA":
          return "Uszkodzona nagroda";
        case "INNY":
          return "Inny";
        default:
          return "Nieznany";
      }
    },
    status(claim) {
      switch (claim.status) {
        case "ZŁOŻONA":
          return "Złożona";
        case "ODRZUCONA":
          return "Odrzucona";
        case "ZAAKCEPTOWANA":
          return "Zaakceptowana";
        default:
          return "Nieznany";
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Administrator" },
      { title: "Reklamacje" },
      { title: "" }
    ]);
    ApiService.get("/all-claims").then(response => {
      this.claims.items = response.data;
      this.state = "loaded";
    });
  }
};
</script>
